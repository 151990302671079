const getLocalStorage = () => {
  try {
    const obj = window.localStorage;
    if ([obj, obj.getItem, obj.setItem].some((v) => !v)) return null;
    return obj;
  } catch {
    return null;
  }
};

export default getLocalStorage;
